import React, { useState, useRef, useEffect } from 'react';
import { MdOutlineFileDownload } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import portada from "../img/portada.png"
import partner1 from "../img/2.png";
import partner2 from "../img/3.png";
import Header from "./Header";


const Main = () => {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIsVisible(entry.isIntersecting);
          },
          {
            root: null, // Observa en relación al viewport
            rootMargin: '0px',
            threshold: 0.1 // Se activa cuando al menos el 10% del elemento está visible
          }
        );
        const current = elementRef.current
        if (current) {
          observer.observe(current);
        }
    
        // Limpieza para evitar fugas de memoria
        return () => {
          if (current) {
            observer.unobserve(current);
          }
        };
    }, []);

    return(
        <section className="mb-5 position-relative overflow-hidden">
            <figure className="m-0 portada-max-height">
                <img src={portada} className="w-100 h-100" alt="Portada" />
            </figure>
            <Header/>
            <div className="container">
                <div className="position-absolute top-28">
                    <h1 ref={elementRef} className={`text-uppercase d-flex flex-column fw-bold mb-3 h1-size mb-title ${isVisible?"tracking-in-contract-bck":""} `}>
                        <span>material</span>
                        <span>médico</span>
                        <span>quirúrgico</span>
                    </h1>
                    <div className="d-flex gap-2 align-items-center mb-3 mb-download">
                        <NavLink to='https://heyzine.com/flip-book/6dbf556302.html' className="d-flex gap-2 align-items-center" rel="noopener noreferrer nofollow" target="_blank">
                            <span className="btn-download-icon pulse">
                                <MdOutlineFileDownload size={30}/>
                            </span>
                            <h3 className="color-portinova d-flex flex-column fw-bold lh-1 h3-size flex-row-edit">
                                <span>Ver</span>
                                <span>Catálogo</span>
                            </h3>
                        </NavLink>
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                        <div className="color-portinova w-50 p-description-main">
                            <p className="lh-1">Distribuidores exclusivos para Guatemala y&nbsp;Centroamérica</p>
                        </div>
                        <div className="d-flex gap-1 w-50">
                            <figure>
                                <img src={partner1} className="w-100 h-100" alt="partners"/>
                            </figure>
                            <figure>
                                <img src={partner2} className="w-100 h-100" alt="partners"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            

        </section>
    )

}

export default Main